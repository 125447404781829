import { CircularProgress } from '@mui/material'

type LoadingSpinnerProps = {
  textAlign?: 'center' | 'left' | 'right'
  loading?: boolean
  color?: string
  size?: string | number
  marginTop?: string
  marginLeft?: string
}

/**
 * Component to display a loading spinner
 * @param textAlign Text alignment for the spinner
 */
export default function LoadingSpinner({
  textAlign = 'center',
  loading = true,
  color = 'var(--bright-purple)',
  size = 40,
  marginTop = '0',
  marginLeft = '0',
}: LoadingSpinnerProps) {
  return (
    <div
      style={{
        textAlign,
        marginTop,
        marginLeft,
      }}
    >
      {loading && <CircularProgress size={size} sx={{ color }} />}
    </div>
  )
}
