import { Configuration } from '../types'

import { fetchHelper } from './auth'

type ConfigurationType = 'student' | 'teacher' | 'school'

/**
 * Get configuration. This is used to get the configuration for the student, teacher, or school.
 * @param type
 * @return Configuration
 */
async function getConfiguration(type: ConfigurationType): Promise<Configuration> {
  return fetchHelper(`configuration/${type}`, 'GET', null) as Promise<Configuration>
}

/**
 * Save configuration. This is used to save the configuration for the student, teacher, or school.
 * @param type
 * @param key
 * @param value
 * @return Configuration
 */
async function saveConfiguration(
  type: ConfigurationType,
  key: string,
  value: string
): Promise<Configuration> {
  return fetchHelper(`configuration/${type}`, 'POST', {
    key,
    value,
  }) as Promise<Configuration>
}

export { getConfiguration, saveConfiguration }
