import type {
  BalanceStats,
  ActivitiesStatsType,
  AcademicProjection,
  PeriodMeta,
  Activity,
  UserSummary,
  PeriodStudent,
  StudentActivityStats,
  SignedUrlMeta,
} from '../types'

import { fetchHelper } from './auth'

/**
 * Get user balance stats. Used on student home page.
 * @return A promise that resolves to an object of type BalanceStats.
 */
async function getUserBalanceStats(studentId?: string): Promise<BalanceStats> {
  return fetchHelper(
    `utility/student/balance/stats?studentId=${studentId}`,
    'GET',
    null
  ) as Promise<BalanceStats>
}

/**
 * Get academic projections for students.
 * @return A promise that resolves to an object of type AcademicProjections.
 */
async function getAcademicProjections(): Promise<AcademicProjection[]> {
  return fetchHelper(`utility/student/academic-projections`, 'GET', null) as Promise<
    AcademicProjection[]
  >
}

/**
 * Get current balance for a list of students.
 * @return A promise that resolves to an object of type ActivitiesStatsType.
 */
async function getStudentsBalance(
  students: string[],
  filters: { [key: string]: string } = {}
): Promise<ActivitiesStatsType> {
  // If there are no students, return an empty object
  if (students.length === 0) {
    return Promise.resolve({} as ActivitiesStatsType)
  }
  const params: URLSearchParams = new URLSearchParams(filters)
  const path = `utility/student/balance/?${params.toString()}`
  return fetchHelper(path, 'POST', {
    students,
  }) as Promise<ActivitiesStatsType>
}

type Contact = {
  userEmail: string
  userMessage: string
  message: string
}
/**
 * Submit contact form
 * @return {Promise<Contact>}
 */
async function postContact(
  userName: string,
  userSchool: string,
  userEmail: string,
  userRole: string,
  userMessage: string
): Promise<Contact> {
  return fetchHelper('utility/contact', 'POST', {
    userName,
    userSchool,
    userEmail,
    userRole,
    userMessage,
  }) as Promise<Contact>
}

/**
 * Get period meta data
 * @return {Promise<PeriodMeta>}
 */
async function getPeriodMeta(): Promise<PeriodMeta> {
  return fetchHelper('utility/teacher/period-meta', 'GET', null) as Promise<PeriodMeta>
}

/**
 * Get absent students for a school for todat
 * @return {Promise<Array<Activity>>}
 */
async function getAbsentStudents(date?: string): Promise<Activity[]> {
  return fetchHelper(`utility/teacher/absent?date=${date}`, 'GET', null) as Promise<Activity[]>
}

/**
 * Toggle absent status for a student
 * @param studentId
 * @param externalStudentId
 * @param periodNumber
 * @param internalPeriodId
 * @param gradeLevel
 * @param markAbsent
 * @return {Promise<Array<Activity>>}
 */
async function toggleAbsent(
  studentData: PeriodStudent,
  periodNumber: number,
  internalPeriodId: string,
  markAbsent: boolean
): Promise<{
  deletedCount: number
}> {
  return fetchHelper('utility/teacher/absent', markAbsent ? 'POST' : 'DELETE', {
    studentId: studentData.studentId,
    externalStudentId: studentData.externalStudentId,
    periodNumber,
    internalPeriodId,
    gradeLevel: studentData.gradeLevel,
    studentFullName: studentData.fullName,
  }) as Promise<{
    deletedCount: number
  }>
}

type PotentialCoTeachersResponse = {
  message?: string
  coTeachers: UserSummary[]
}

/**
 * Get potential co-teachers for a school
 * @return {Promise<PotentialCoTeachersResponse>}
 */
async function getPotentialCoTeachers(): Promise<PotentialCoTeachersResponse> {
  return fetchHelper(
    'utility/list/co-teachers',
    'GET',
    null
  ) as Promise<PotentialCoTeachersResponse>
}

/**
 * Get dashboard stats for student
 * @return {Promise<StudentActivityStats>}
 */
async function getDashboardStats(
  filters: { [key: string]: string } = {}
): Promise<StudentActivityStats> {
  const params: URLSearchParams = new URLSearchParams(filters)
  return fetchHelper(
    `utility/student/dashboard/stats?${params.toString()}`,
    'GET',
    null
  ) as Promise<StudentActivityStats>
}

/**
 * Get signed URL for reading files
 * @return {Promise<SignedUrlMeta>}
 */
async function getSignedURL(type: 'REDEEMED_ORDERS' | 'PENDING_ORDERS'): Promise<SignedUrlMeta> {
  return fetchHelper(
    `utility/signed-url/${type}?time=${new Date().getTime()}`,
    'GET',
    null
  ) as Promise<SignedUrlMeta>
}

/**
 * Get signed URL for uploading files
 * @return {Promise<SignedUrlMeta>}
 */
async function getUploadURL(extension: string): Promise<SignedUrlMeta> {
  return fetchHelper(
    `utility/upload-url/${extension}?time=${new Date().getTime()}`,
    'GET',
    null
  ) as Promise<SignedUrlMeta>
}

export {
  postContact,
  getUserBalanceStats,
  getStudentsBalance,
  getAcademicProjections,
  getPeriodMeta,
  getAbsentStudents,
  toggleAbsent,
  getPotentialCoTeachers,
  getDashboardStats,
  getUploadURL,
  getSignedURL,
}
