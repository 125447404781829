import { PayPeriod } from '../types'

const ActivityCategory = {
  FLAGS: 'Flags',
  BIZI_BONUS: 'Daily Bizi Bonus',
  SPECIAL_BIZI_BONUS: 'Special Bizi Bonus',
  ACADEMICS: 'Academics Pay Day',
  DAILY_ATTENDANCE: 'Daily Attendance',
  DAILY_BEHAVIOR: 'Daily Behavior',
}

const TITLE_PREFIX = 'Bizi App'

const GOOGLE_CDN_URL_PREFIX = 'https://storage.googleapis.com/bizi-asset'
const STORE_IMAGE_URL_PREFIX = GOOGLE_CDN_URL_PREFIX
const CARD_IMAGE_URL_PREFIX = `${GOOGLE_CDN_URL_PREFIX}/CreditCards`
const APP_IMAGE_URL_PREFIX = `${GOOGLE_CDN_URL_PREFIX}/AppImages`

const ROUTES = {
  LOGIN: {
    path: '/login',
    key: 'LoginPage',
    title: `${TITLE_PREFIX} - Login`,
  },
  CUSTOM_LOGIN: {
    path: '/login/custom',
    key: 'CustomLoginPage',
    title: `${TITLE_PREFIX} - Custom Login`,
  },
  STATUS: {
    path: '/status',
    key: 'StatusPage',
    title: `${TITLE_PREFIX} - Status`,
  },
  HOW_TO_EARN: {
    path: '/how-to-earn',
    key: 'HowToEarnPage',
    title: `${TITLE_PREFIX} - How to Earn`,
  },
  STORE: {
    path: '/store',
    key: 'StorePage',
    title: `${TITLE_PREFIX} - Store`,
  },
  ORDERS: {
    path: '/orders',
    key: 'OrdersPage',
    title: `${TITLE_PREFIX} - Orders`,
  },
  CLASSES: {
    path: '/classes',
    key: 'ClassesPage',
    title: `${TITLE_PREFIX} - Classes`,
  },
  CLASS: {
    path: '/class',
    key: 'ClassPage',
    title: `${TITLE_PREFIX} - Roster`, // Roster is the default tab
  },
  ROSTERS: {
    path: '/rosters',
    key: 'RostersPage',
    title: `${TITLE_PREFIX} - Rosters`,
  },
  DASHBOARD: {
    path: '/dashboard',
    key: 'StudentsDashboardPage',
    title: `${TITLE_PREFIX} - Dashboard`,
  },
  ACTIVITIES: {
    path: '/activity',
    key: 'StudentsActivitiesPage',
    title: `${TITLE_PREFIX} - Activities`,
  },
  REPORTS: {
    path: '/reports',
    key: 'TeachersReportsPage',
    title: `${TITLE_PREFIX} - Reports`,
  },
  CONTACT: {
    path: '/contact',
    key: 'ContactPage',
    title: `${TITLE_PREFIX} - Contact`,
  },
  PROFILE: {
    path: '/profile',
    key: 'ProfilePage',
    title: `${TITLE_PREFIX} - Profile`,
  },
  CUSTOMIZE_BIZI_BONUSES_FLAGS: {
    path: '/customize-bonuses-flags',
    key: 'CustomizeBonusesAndFlags',
    title: `${TITLE_PREFIX} - Customize Bizi Bonuses and Flags`,
  },
  ADMIN_BIZI_BONUSES_FLAGS: {
    path: '/bonuses-flags',
    key: 'AdminActionsPage',
    title: `${TITLE_PREFIX} - Admin Bizi Bonuses and Flags`,
  },
  CALENDAR: {
    path: '/calendar',
    key: 'CalendarPage',
    title: `${TITLE_PREFIX} - Calendar`,
  },
  PAY_PERIOD_BREAKDOWN: {
    path: '/pay-period-breakdown',
    key: 'PayPeriodBreakdown',
    title: `${TITLE_PREFIX} - Pay Period Breakdown`,
  },
  // Has to be last
  LANDING: {
    path: '/',
    key: 'LandingPage',
    title: `${TITLE_PREFIX}`,
  },
}

const ALL_ORDER_STATUSES = ['ALL', 'PENDING', 'REDEEMED', 'REFUNDED', 'CANCELLED']

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const TOOLTIP_STYLE = {
  bgcolor: 'common.black',
  padding: '1rem',
  fontSize: '1rem',
  whiteSpace: 'pre-line',
  '& .MuiTooltip-arrow': {
    color: 'common.black',
  },
}

/** User Roles */
const UserRole = {
  USER: 'USER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  STAFF: 'STAFF',
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
  STORE_MANAGER: 'STORE_MANAGER',
  GIVE_ALL: 'GIVE_ALL',
  CUSTOMIZE_STORE: 'CUSTOMIZE_STORE',
  TEACHER_USAGE_REPORTS: 'TEACHER_USAGE_REPORTS',
  SCHOOL_STUDENT_REPORTS: 'SCHOOL_STUDENT_REPORTS',
  SCHOOL_REPORTS: 'SCHOOL_REPORTS',
}

const yearToDatePayPeriod: PayPeriod = {
  _id: 'year-to-date',
  type: 'Year',
  name: `Year to Date`,
  start: new Date('2024/08/01').toISOString(),
  end: new Date().toISOString(),
}

const augustPayPeriod: PayPeriod = {
  _id: 'August',
  type: 'August',
  name: 'August',
  start: new Date('2024/08/01').toISOString(),
  end: new Date('2024/08/31').toISOString(),
}

const septemberPayPeriod: PayPeriod = {
  _id: 'September',
  type: 'September',
  name: 'September',
  start: new Date('2024/09/01').toISOString(),
  end: new Date('2024/09/30').toISOString(),
}

const octoberPayPeriod: PayPeriod = {
  _id: 'October',
  type: 'October',
  name: 'October',
  start: new Date('2024/10/01').toISOString(),
  end: new Date('2024/10/31').toISOString(),
}

const novemberPayPeriod: PayPeriod = {
  _id: 'November',
  type: 'November',
  name: 'November',
  start: new Date('2024/11/01').toISOString(),
  end: new Date('2024/11/30').toISOString(),
}

const decemberPayPeriod: PayPeriod = {
  _id: 'December',
  type: 'December',
  name: 'December',
  start: new Date('2024/12/01').toISOString(),
  end: new Date('2024/12/31').toISOString(),
}

const validMonths = [
  augustPayPeriod,
  septemberPayPeriod,
  octoberPayPeriod,
  novemberPayPeriod,
  decemberPayPeriod,
]

export {
  ActivityCategory,
  UserRole,
  ROUTES,
  ALL_ORDER_STATUSES,
  TITLE_PREFIX,
  MONTH_NAMES,
  TOOLTIP_STYLE,
  STORE_IMAGE_URL_PREFIX,
  APP_IMAGE_URL_PREFIX,
  CARD_IMAGE_URL_PREFIX,
  yearToDatePayPeriod,
  validMonths,
}
