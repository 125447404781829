import type { Group, Team } from '../types'

import { fetchHelper } from '.'

type GroupsResponse = {
  message?: string
  groups: Group[]
}

async function getTeacherGroups(internalPeriodId: string): Promise<GroupsResponse> {
  return fetchHelper(
    `group?internalPeriodId=${internalPeriodId}`,
    'GET',
    null
  ) as Promise<GroupsResponse>
}

async function createNewGroup(internalPeriodId: string, teams: Team[]): Promise<Group> {
  return fetchHelper('group', 'POST', {
    teams,
    internalPeriodId,
  }) as Promise<Group>
}

async function updateExistingGroup(groupId: string, name: string, teams: Team[]): Promise<Group> {
  return fetchHelper(`group/${groupId}`, 'PUT', {
    teams,
    name,
  }) as Promise<Group>
}

async function deleteExistingGroup(groupId: string): Promise<Group> {
  return fetchHelper(`group/${groupId}`, 'DELETE', null) as Promise<Group>
}

async function saveGroupActivity(
  groupId: string,
  activityType: string,
  date: Date,
  externalTeacherId: string,
  teamId: string
) {
  return fetchHelper(`group/${groupId}/team/${teamId}/activity`, 'POST', {
    activityType,
    date,
    externalTeacherId,
  })
}

async function deleteGroupActivitiesForTeam(groupId: string, teamId: string) {
  return fetchHelper(`group/${groupId}/team/${teamId}/activity`, 'DELETE', { teamId })
}

export {
  getTeacherGroups,
  createNewGroup,
  updateExistingGroup,
  deleteExistingGroup,
  saveGroupActivity,
  deleteGroupActivitiesForTeam,
}
