import type { Store, StoreItem } from '../types'

import { fetchHelper } from './auth'

/**
 * Get store for logged in user
 * @return {Promise<Store>}
 */
async function getStore(): Promise<Store> {
  return fetchHelper(`store?time=${new Date().getTime()}`, 'GET', null) as Promise<Store>
}

type UpdateStoreItemResponse = {
  isApprovalRequired: boolean
  message: string
}
/**
 * Update store item. If storeItem._id is not set, it will create a new store item
 * @param {StoreItem} storeItem
 * @return {Promise<UpdateStoreItemResponse>}
 */
async function updateStoreItem(storeItem: StoreItem): Promise<UpdateStoreItemResponse> {
  return fetchHelper(
    `store/item/${storeItem._id}`,
    storeItem._id ? 'PUT' : 'POST',
    storeItem
  ) as Promise<UpdateStoreItemResponse>
}

export { getStore, updateStoreItem }
