import type { Period } from '../types'

import { fetchHelper } from './auth'

/**
 * Get Teacher periods
 * @return {Promise<Array<Period>>}
 */
async function getTeacherPeriods(): Promise<Array<Period>> {
  return fetchHelper('period/teacher/all', 'GET', null) as Promise<Array<Period>>
}

/**
 * Get School periods
 * @return {Promise<Array<Period>>}
 */
async function getSchoolPeriods(): Promise<Array<Period>> {
  return fetchHelper('period/school/all', 'GET', null) as Promise<Array<Period>>
}

/**
 * Retrieves a period by its internal ID.
 * @param internalPeriodId - The internal ID of the period.
 * @return A Promise that resolves to the retrieved Period object.
 */
async function getPeriod(internalPeriodId: string): Promise<Period> {
  return fetchHelper(`period/${internalPeriodId}`, 'GET', null) as Promise<Period>
}

/**
 * Updates the co-teachers of a period.
 * @param internalPeriodId - The internal ID of the period.
 * @param coTeachers - An array of the internal IDs of the co-teachers.
 * @return A Promise that resolves to the updated Period object.
 */
async function updateCoTeachers(internalPeriodId: string, coTeachers: string[]): Promise<Period> {
  return fetchHelper(`period/${internalPeriodId}/co-teachers`, 'PUT', {
    coTeachers,
  }) as Promise<Period>
}

export { getPeriod, getTeacherPeriods, getSchoolPeriods, updateCoTeachers }
