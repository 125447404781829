import type { Cart, CartItem } from '../types'

import { fetchHelper } from '.'

/**
 * Get cart for logged in user
 * @return {Promise<Cart>}
 */
async function getCart(): Promise<Cart> {
  return fetchHelper('cart', 'GET', null) as Promise<Cart>
}

/**
 * Save cart for logged in user
 * @return {Promise<Cart>}
 */
async function saveCart(cartItems: CartItem[]): Promise<Cart> {
  return fetchHelper('cart', 'POST', { cartItems }) as Promise<Cart>
}

/**
 * Delete cart for logged in user
 */
async function deleteCart() {
  fetchHelper('cart', 'DELETE', null)
}

export { getCart, saveCart, deleteCart }
