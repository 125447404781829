import type { User } from '../types'

import { fetchHelper } from '.'

/**
 * Login via username and password
 * @param username
 * @param password
 * @return {Promise<{user: User, token: string, message: string}>}
 */
async function doLogin(
  username: string,
  password: string
): Promise<{ user: User; token: string; message: string }> {
  return fetchHelper('auth/login', 'POST', { username, password }) as Promise<{
    user: User
    token: string
    message: string
  }>
}

/**
 * Login via clever code
 * @param code
 * @param state
 * @return {Promise<{user: User, token: string, message: string}>}
 */
async function doCleverLogin(
  code: string,
  state: string
): Promise<{ user: User; token: string; message: string }> {
  return fetchHelper('auth/login/clever', 'POST', { code, state }) as Promise<{
    user: User
    token: string
    message: string
  }>
}
/**
 * Get Clever SSO state
 * @return {Promise<{state: string, message: string}>}
 */
async function getCleverState(): Promise<{ state: string; message: string }> {
  return fetchHelper('auth/login/clever/state', 'GET', null) as Promise<{
    state: string
    message: string
  }>
}

/**
 * Get logged in user details
 * @return {Promise<User>}
 */
async function me(): Promise<User> {
  if (localStorage.getItem('authtoken')) {
    return fetchHelper('auth/me', 'POST', null) as Promise<User>
  }
  return Promise.resolve({ message: 'No token' } as User)
}

export { doLogin, doCleverLogin, me, fetchHelper, getCleverState }
