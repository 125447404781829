import { TOOLTIP_STYLE } from '../../utility/constants'

import './style.css'
import { Tooltip } from '@mui/material'

type RouteLinkProps = {
  isSlim?: boolean
  id?: string
  name: string
  link: string
  icon: JSX.Element
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

/**
 * Component for the side header link.
 */
export default function RouteLink(props: RouteLinkProps) {
  const { id, name, link, icon, onClick, isSlim } = props
  return (
    <div
      id={id}
      className={`header-route ${isSlim ? 'sm' : ''} ${
        window.location.pathname.includes(link) ? 'selected' : ''
      }`}
      key={link}
      onClick={onClick}
      tabIndex={1}
      onKeyDown={(e: React.KeyboardEvent) => {
        // If the user presses enter, we want to trigger the onClick event
        if (e.key === 'Enter') onClick(e as unknown as React.MouseEvent<HTMLElement>)
      }}
    >
      <Tooltip
        placement="right"
        title={name}
        componentsProps={{
          tooltip: {
            sx: TOOLTIP_STYLE,
          },
        }}
      >
        <div className={`header-route-icon ${isSlim ? 'sm' : ''}`}>{icon}</div>
      </Tooltip>
      <div className={isSlim ? 'hide' : 'hide-small'}>
        <span>{name}</span>
      </div>
    </div>
  )
}
