import { APP_IMAGE_URL_PREFIX } from '../../utility/constants'

import RouteLink from './RouteLink'
import { CalendarAppointmentDate, HomeHouse, Info, PaperNote, ShoppingCart } from 'react-basicons'
import { useNavigate } from 'react-router-dom'

/**
 * Component for the header of the student pages.
 */
function StudentHeader({ isSlim }: { isSlim: boolean }) {
  const navigate = useNavigate()

  const pages = [
    {
      name: 'Dashboard',
      link: '/dashboard',
      icon: <HomeHouse size={22} />,
      disabled: false,
    },
    {
      name: 'My Activity',
      link: '/activity',
      icon: <img src={`${APP_IMAGE_URL_PREFIX}/ChartUpArrow.svg`} height={22} />,
      disabled: false,
    },
    {
      name: 'My Store',
      link: '/store',
      icon: <ShoppingCart size={22} />,
      disabled: false,
    },
    {
      name: 'My Orders',
      link: '/orders',
      icon: <PaperNote size={22} />,
      disabled: false,
    },
    {
      name: 'Calendar',
      link: '/calendar',
      icon: <CalendarAppointmentDate size={22} />,
      hideFor: [],
      disabled: false,
    },
    {
      name: 'How To Earn',
      link: '/how-to-earn',
      icon: <Info size={22} />,
      disabled: true,
    },
  ]

  return (
    <>
      {pages.map((page) => (
        <RouteLink
          isSlim={isSlim}
          key={page.link}
          {...page}
          onClick={() => {
            navigate(page.link)
          }}
        />
      ))}
    </>
  )
}
export default StudentHeader
