import type { Status } from '../types'
import { captureSentryException, captureSentryMessage } from '../utility'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

/**
 * Fetch helper to call external APIs
 * @param {*} url
 * @param {*} method
 * @param {*} payload
 * @return {Promise}
 */
async function fetchHelper(url: string, method: string, payload: object | null) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('authtoken', localStorage.getItem('authtoken') ?? '')

  const body = payload ? JSON.stringify(payload) : null

  const requestOptions: RequestInit = {
    method,
    headers,
  }

  if (payload) {
    requestOptions.body = body
  }

  return new Promise((resolve) => {
    let status = 0
    fetch(`${API_BASE_URL}/${url}`, requestOptions)
      .then(async (response) => {
        if (response.status === 401) {
          if (
            !['/login/custom', '/login', '/contact', '/status'].includes(window.location.pathname)
          ) {
            window.location.href = '/login'
          }
        }
        status = response.status
        // No content. Return empty object
        if ([204, 307, 503, 502].includes(status)) {
          return '{}' // Return empty object
        }
        if (response.ok) {
          const text = await response.text()
          return text ?? `{"statusCode":${response.status},"message":"Error fetching data"}`
        }
        const text = await response.text()
        let error = 'Some error occured. Try again later.'
        if (text) {
          error = text ? parseJson(text, url, status)?.message ?? error : error
        }
        throw new Error(error)
      })
      .then((data) => {
        resolve(parseJson(data, url, status))
      })
      .catch((error) => {
        captureSentryException(error)
        resolve({ message: error.message })
      })
  })
}

/**
 * Parse JSON data safely
 * @param {*} data
 * @return {Object}
 */
function parseJson(data: string, url: string = '', statusCode: number = 0): { message?: string } {
  try {
    return JSON.parse(data)
    // eslint-disable-next-line
  } catch (e: any) {
    captureSentryMessage(`Error parsing JSON - ${statusCode} - ${url.split('?')[0]} - ${data}`)
    return {}
  }
}

/**
 * Get backend status
 * @return {Promise<Status>}
 */
async function getStatus(): Promise<Status> {
  return fetchHelper('status', 'GET', null) as Promise<Status>
}

export * from './cart'
export * from './orders'
export * from './activity'
export * from './auth'
export * from './utility'
export * from './group'
export * from './store'
export * from './period'
export * from './configuration'
export { getStatus, fetchHelper }
